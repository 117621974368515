import { Outlet, useLocation } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import "../assets/css/common.scss";
import useAuth from "../hooks/useAuth";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const Layout = () => {
  const location = useLocation();
  const { auth } = useAuth();

  return (
    <div className="App">
      <header
        className={`${location.pathname === "/" && !auth.token
            ? "App-header-home"
            : "App-header"
          }`}
      >
        <Header />
      </header>
      <div
        className={`layout-view-content ${location.pathname === "/" && !auth.token ? "white-bg" : ""
          }`}
      >
       <Outlet/>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        {/* Same as */}
      </div>
      <footer className="App-footer">
        <Footer />
      </footer>
    </div>
  );
};

export default Layout;
