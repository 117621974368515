import { NavLink } from "react-bootstrap";
import { useNavigate } from "react-router";

const Footer = () => {

  const navigate = useNavigate();

  return (
    <div className="row px-0 mx-0">
      <ul className="col-12 d-flex justify-content-center px-0 mb-0">
        <li className="footer-link py-3">
          <NavLink onClick={() => {navigate('/terms-and-conditions')}}>Terms and Conditions</NavLink>
        </li>
        <li className="footer-link py-3">
          <NavLink onClick={() => {navigate('/contact-us')}}>Contact Us</NavLink>
        </li>
      </ul>
    </div>
  );
};

export default Footer;
