import React from "react";
import homeimg from "../assets/images/home_girl_img.png";
import HomeArrow from "../assets/images/home-arrow.svg";
import "../components/home.scss";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import Profile from "../views/Profile";

const Home = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { auth } = useAuth();

  const isVerifiedUser = () => {
    if (auth.role === "parent" && auth.isUserVerified) {
      return true;
    } else if (
      auth.role === "sitter" &&
      auth.isBsParentVerified &&
      auth.isUserVerified
    ) {
      return true;
    }
    return false;
  };

  // Update meta description directly
  document.title = "Find a Great Babysitter HighSchoolBabysitters.com";

  const metaDescription = document.querySelector('meta[name="description"]');
  if (metaDescription) {
    metaDescription.setAttribute(
      "content",
      "HighSchoolBabysitters.com connects hardworking, trustworthy teens with parents in need of local babysitters."
    );
  }

  return (
    <React.Fragment>
      {auth && auth.token && isVerifiedUser() ? (
        <Profile />
      ) : auth && auth.token && !isVerifiedUser() ? (
        <Navigate
          to="/pending-verification"
          state={{ from: location }}
          replace
        />
      ) : (
        <div className="container-fluid mx-0 px-0 home-bg">
          <div className="row  px-0 mx-0 align-items-center justify-content-center">
            <div className="col-auto  ps-5 pt-3 my-5 d-md-block d-none">
              <div className="home-title sec-one-title">
                {" "}
                <h1>Created for highschoolers</h1>
              </div>

              <div>
                <button
                  className="btn  btn-theme-outline my-1  me-3"
                  onClick={() => {
                    navigate("parent-register");
                  }}
                >
                  Find a Babysitter
                </button>
                <button
                  className="btn btn-theme  my-1"
                  type="submit"
                  onClick={() => {
                    navigate("/register");
                  }}
                >
                  Register To Be A Babysitter
                </button>
              </div>
            </div>
            <div className="col-auto  ms-auto home-rel pe-0 me-0">
              <div className="home-arrow">
                <img
                  src={HomeArrow}
                  className="d-md-block d-none home-arrow-img"
                />
              </div>
              <div className="sec-two-img">
                <img src={homeimg} className="girl-img" />
              </div>
            </div>

            <div className="d-block d-md-none">
              <div className="position-relative">
                <div className="home-title-mobile sec-one-title-mobile title-pos-mobile">
                  <h1 className="m-0">Created for</h1>{" "}
                  <h1 className="m-0">highschoolers</h1>
                </div>
              </div>
              <div className="row text-center mt-3">
                <div className="col-12">
                  <button
                    className="btn btn-theme"
                    type="submit"
                    onClick={() => {
                      navigate("/register");
                    }}
                  >
                    Register To Be A Babysitter
                  </button>
                </div>
                <div className="col-12">
                  <button
                    className="btn btn-theme-outline mt-3"
                    onClick={() => {
                      navigate("parent-register");
                    }}
                  >
                    Find a Babysitter
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default Home;
