import { useState } from "react";
import React from "react";
import "../assets/css/common.scss";
import Thumbnail from "../assets/images/thumbnail-img.jpg";

const HowitWorksVideo = () => {
  // Update meta description directly
  document.title = "How HighSchoolBabysitters.com Works";

  const metaDescription = document.querySelector('meta[name="description"]');
  if (metaDescription) {
    metaDescription.setAttribute(
      "content",
      "Both parents and highschoolers need to go through a few key steps to find a secure, local, well-suited match for babysitting."
    );
  }
  const workVideo = require("../../src/assets/images/video.mp4");
  return (
    <div className="parent-layout">
      <p className="page-title text-center text-uppercase mt-3 mb-4 video-pointer">
        How It works
      </p>
      <div>
        <div className="row layout-box">
          <video controls poster={Thumbnail}>
            <source src={workVideo} type="video/mp4"></source>
          </video>
        </div>
      </div>
    </div>
  );
};

export default HowitWorksVideo;
