import React from "react";
import "../assets/css/common.scss";
import "../components/storyPage.scss";
import about_1 from "../assets/images/about.png";
import testimonial from "../assets/images/testimonial.png";

const OurStory = () => {
  // Update meta description directly
  document.title = "Babysitting Created by and for Highschoolers";

  const metaDescription = document.querySelector('meta[name="description"]');
  if (metaDescription) {
    metaDescription.setAttribute(
      "content",
      "We created HighSchoolBabysitters.com to connect highschoolers searching for babysitting jobs and parents of young children in need of child care."
    );
  }
  return (
    <div>
      <div className="parent-layout">
        <p className="page-title text-center text-uppercase mt-3 mb-4">
          Our Story
        </p>
        <div>
          <div className="row layout-box ">
            <div className="col-12 col-md-8">
              <p className="text-uppercase section-first-title">
                created by a highschooler for highschoolers
              </p>
              <p className="description-style pb-sm-4 pb-0">
                HighschoolBabysitters.com was created to connect highschoolers
                looking for babysitting jobs and parents of little kids trying
                to find highschool babysitters.{" "}
              </p>
            </div>
            <div className="d-md-block d-none col-md-4 position-relative">
              <div className="circle-one">
                <img
                  src={about_1}
                  alt="Your SVG"
                  className="circle-img-one img-thumbail"
                />
              </div>
            </div>
          </div>

          <div className="d-block d-md-none position-relative">
            <div className="circle-one">
              <img
                src={about_1}
                alt="Your SVG"
                className="mobile-circle img-thumbail"
              />
            </div>
          </div>

          <div className="row layout-box box-two-top">
            <div className="col-md-4 d-md-block d-none position-relative">
              <div className="circle-two">
                <img
                  src={testimonial}
                  alt="Your SVG"
                  className="circle-img img-thumbail"
                />
              </div>
            </div>
            <div className="col-12 col-md-8">
              <p className="text-uppercase section-first-title">Meet aspen</p>
              <p className="description-style pb-sm-4 pb-0 ms-auto">
                {" "}
                I am a Sophomore in high school in Colorado. I want to thank you
                for using HighSchoolBabysitters.com. I made this website for all
                of you. I hope it can help all my friends find more babysitting
                jobs and give parents of little kids a way to find high school
                babysitters in their home towns.{" "}
              </p>
            </div>
          </div>
          <div className="d-block d-md-none position-relative">
            <div className="circle-two">
              <img
                src={testimonial}
                alt="Your SVG"
                className="mobile-circle img-thumbail"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    // <div >
    //  <div className="parent-layout">
    //     <p className='page-title text-center text-uppercase mt-3 mb-4'>Our Story</p>
    //     <div>
    //         <div className="row layout-box ">
    //             <div className="col-12 col-md-8">

    //                 <p className='text-uppercase section-first-title'>created by a highschooler for highschoolers</p>
    //                 <p className='description-style pb-4'>HighschoolBabysitters.com was created to connect highschoolers looking for babysitting jobs and parents of little kids trying to find highschool babysitters.  </p>

    //                </div>
    //                 <div className="d-md-block d-none col-md-4 position-relative">
    //                <div className="circle-one">
    //               <img src={circle} alt="Your SVG"  className='circle-img-one'/>
    //             </div>
    //             </div>
    //         </div>

    //         <div  className="d-block d-md-none position-relative">
    //                <div className='circle-one'>
    //               <img src={circle} alt="Your SVG"  style={{height:"120px",width:"120px"}}/>
    //             </div>
    //             </div>

    //         <div className="row layout-box" style={{marginTop:"123px"}}>

    //             <div className="col-md-4 d-md-block d-none position-relative">
    //             <div className='circle-two'>
    //               <img src={circle} alt="Your SVG"  className='circle-img'/>
    //             </div>
    //             </div>
    //     <div className='col-12 col-md-8'>
    //                 <p className='text-uppercase section-first-title'>Meet aspen</p>
    //                 <p  className="description-style pb-4"> I am a Sophmore in Highschool in Evergreen Colorado.  I want to thank you for using HighschoolBabysitters.com.  I made this website for all of you.  I hope it can help my friends find more babysitting jobs and give parents of little kids find more highschool babysitters in their home towns. </p>
    //                 </div>
    //         </div>
    //         <div className="d-block d-md-none position-relative">
    //             <div className='circle-two'>
    //               <img src={circle} alt="Your SVG"  style={{height:"120px",width:"120px"}}/>
    //             </div>
    //             </div>
    //     </div>
    //  </div>
    // </div>
  );
};

export default OurStory;
